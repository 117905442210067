 <template>
  <div style="height: 1000px">
   <div style="width:10%;margin:auto;margin-top:100px">
   <el-input v-model="input" placeholder="请输入内容"></el-input>
     <el-tree
        style="background-color: #000; width: 90%; margin: auto;"
        :style="{'opacity':num}"
        ref="tree"
        :data="data"
        @node-click="handleNodeClick"
        :filter-node-method="filterNode"
        :accordion="true"
      ></el-tree>
   </div>
  </div>
</template>
 
 <script>
export default {
  data() {
    return {
      input: "",
      show :false,
      num:0,
       data: [
       
        // {
        //   label: "产品与服务",
        //   children: [
        //     {
        //       label: "硬件产品",
        //       children: [
        //         {
        //           label: "PLC控制柜",
        //           index: "HardwareDetail/2",
        //           leave: "2",
        //         },
        //         {
        //           label: "水利控制器",
        //           index: "HardwareDetail/3",
        //           leave: "2",
        //         },
        //         {
        //           label: "雨情控制器",
        //           index: "HardwareDetail/4",
        //           leave: "2",
        //         },
        //         {
        //           label: "门禁报警控制器",
        //           index: "HardwareDetail/6",
        //           leave: "2",
        //         },
        //         {
        //           label: "电阻采集控制器",
        //           index: "HardwareDetail/5",
        //           leave: "2",
        //         },
        //         {
        //           label: "温度采集器",
        //           index: "HardwareDetail/12",
        //           leave: "2",
        //         },
        //         {
        //           label: "电流电压采集器",
        //           index: "HardwareDetail/13",
        //           leave: "2",
        //         },
        //         {
        //           label: "继电器控制器",
        //           index: "HardwareDetail/16",
        //           leave: "2",
        //         },
        //         {
        //           label: "设备追回定位器",
        //           index: "HardwareDetail/7",
        //           leave: "2",
        //         },
        //         {
        //           label: "电动车定位器",
        //           index: "HardwareDetail/11",
        //           leave: "2",
        //         },
        //         {
        //           label: "柴油车定位器",
        //           index: "HardwareDetail/8",
        //           leave: "2",
        //         },
        //         {
        //           label: "汽油车定位器",
        //           index: "HardwareDetail/15",
        //           leave: "2",
        //         },
        //       ],
        //     },
        //     {
        //       label: "软件产品",
        //       children: [
        //         {
        //           label: "智慧水利",
        //           children: [
        //             {
        //               label: "智慧水利-大数据可视化",
        //               index: "softwareDetail/1",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧水利-GIS一张图",
        //               index: "softwareDetail/21",
        //               leave: "3",
        //             },
        //             {
        //               label: "无人值守远程控制系统",
        //               index: "softwareDetail/22",
        //               leave: "3",
        //             },
        //             {
        //               label: "雨情自动遥测系统",
        //               index: "softwareDetail/23",
        //               leave: "3",
        //             },
        //             {
        //               label: "自动水位站监测系统",
        //               index: "softwareDetail/24",
        //               leave: "3",
        //             },
        //             {
        //               label: "自动流量站监测系统",
        //               index: "softwareDetail/25",
        //               leave: "3",
        //             },
        //             {
        //               label: "水利设施安防预警系统",
        //               index: "softwareDetail/26",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧水利-后台管理子系统",
        //               index: "softwareDetail/27",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧水利-手机APP",
        //               index: "softwareDetail/28",
        //               leave: "3",
        //             },
        //           ],
        //         },
        //         {
        //           label: "智慧城管",
        //           children: [
        //             {
        //               label: "智慧城管-大数据可视化",
        //               index: "softwareDetail/5",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧城管-GIS一张图",
        //               index: "softwareDetail/6",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧城管-公共服务系统",
        //               index: "softwareDetail/7",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧城管-城市运行APP",
        //               index: "softwareDetail/8",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧水务综合管理平台",
        //               index: "softwareDetail/9",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧照明管理平台",
        //               index: "softwareDetail/10",
        //               leave: "3",
        //             },
        //             {
        //               label: "路长制综合管理平台",
        //               index: "softwareDetail/11",
        //               leave: "3",
        //             },
        //             {
        //               label: "闸泵远程控制系统",
        //               index: "softwareDetail/12",
        //               leave: "3",
        //             },
        //             {
        //               label: "城市防汛监管系统",
        //               index: "softwareDetail/13",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧公厕综合管理平台",
        //               index: "softwareDetail/4",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧环卫综合管理平台",
        //               index: "softwareDetail/14",
        //               leave: "3",
        //             },
        //             {
        //               label: "地热井管控平台",
        //               index: "softwareDetail/15",
        //               leave: "3",
        //             },
        //             {
        //               label: "市容秩序提升平台",
        //               index: "softwareDetail/16",
        //               leave: "3",
        //             },
        //             {
        //               label: "建筑垃圾综合信息管理平台",
        //               index: "softwareDetail/17",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧工地管理平台",
        //               index: "softwareDetail/18",
        //               leave: "3",
        //             },
        //             {
        //               label: "油烟在线监测平台",
        //               index: "softwareDetail/19",
        //               leave: "3",
        //             },
        //             {
        //               label: "智慧园林综合管理平台",
        //               index: "softwareDetail/2",
        //               leave: "3",
        //             },
        //             {
        //               label: "AI视频监控智能分析系统",
        //               index: "softwareDetail/20",
        //               leave: "3",
        //             },
        //           ],
        //         },
        //         {
        //           label: "智慧农业",
        //           children: [
        //             {
        //               label: "智能化灌溉系统",
        //               index: "softwareDetail/3",
        //               leave: "3",
        //             },
        //             {
        //               label: "水肥一体化系统",
        //               index: "softwareDetail/29",
        //               leave: "3",
        //             },
        //             {
        //               label: "远程信息化虫情测报系统",
        //               index: "softwareDetail/30",
        //               leave: "3",
        //             },
        //             {
        //               label: "环境监测系统（自动气象站）",
        //               index: "softwareDetail/31",
        //               leave: "3",
        //             },
        //             {
        //               label: "土壤墒情监控系统",
        //               index: "softwareDetail/32",
        //               leave: "3",
        //             },
        //             {
        //               label: "智能温室监控系统",
        //               index: "softwareDetail/33",
        //               leave: "3",
        //             },
        //             {
        //               label: "农业遥感监测平台",
        //               index: "softwareDetail/34",
        //               leave: "3",
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       label: "其他服务",
        //       children: [
        //         {
        //           label: "硬件定制研发",
        //         },
        //         {
        //           label: "软件定制开发",
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          label: "PLC控制柜",
          index: "HardwareDetail/2",
        },
         {
         label: "水利控制器",
         index: "HardwareDetail/3",
        },
      ],
    };
  },
   watch: {
    input(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods:{
      handleNodeClick(data) {
      if (data.label) {
        this.click(data.index);
      }
    },
     click(val) {
      this.$router.push(val);
    },
      filterNode(value, data) {
      console.log(value, data);
      if (!value) return true ,this.num = 0; this.num = 1
      return data.label.indexOf(value) !== -1;
      
      
    },
  }
};
</script>
 
 <style scoped>
 
</style>